
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import {
  Right,
} from "@element-plus/icons-vue";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";

// Services
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElRow,
    ElCol,
    Right,
    AdministratorNavigationVue,
  },
})
export default class AppAdministratorDashboardRootIndexVue extends Vue {
  get translation(): any {
    return getTranslation([
      "invoices",
      "masterData",
      "organizations",
      "reports",
    ]);
  }
}
